import React, { useRef, useState, useEffect } from "react";

import ComingVideoWeb from "../Video/VideoHome2.mp4";
import ComingVideoMobile from "../Video/VideoHome2.mp4";

export default function HeroHomeVideo() {
  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
  };

  const videoParentRef = useRef();
  const videoRef = useRef(null);
  const [shouldUseImage, setShouldUseImage] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isSafari() && videoRef.current) {
      const player = videoRef.current;

      if (player) {
        player.controls = false;
        player.playsInline = true;
        player.muted = true;
        player.setAttribute("muted", "");
        player.autoplay = true;

        setTimeout(() => {
          const promise = player.play();
          if (promise && promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, [isMobile]);

  const videoSrc = isMobile ? ComingVideoMobile : ComingVideoWeb;

  return (
    <>
      <div className="Home" ref={videoParentRef}>
        <video ref={videoRef} loop muted autoPlay playsInline preload="metadata" className="video-background">
          <source src={videoSrc} type="video/mp4" />
        </video>
      </div>
    </>
  );
}
